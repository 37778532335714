.settings-main-container {
  display: flex;
  gap: 15px;
  background-color: white;
  padding-top: 10px;
}

.settings-left-side {
  border: solid 1px black;
  width: 150px;
  text-align: center;
  border-radius: 10px;
  padding: 5px;
  height: fit-content;
  position: sticky;
  top: 10px;
}

.settings-left-side > div {
  min-height: 40px;
  min-width: 110px;
  color: black;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-around;
}

.settings-left-side > div:hover {
  background-color: #b1b1b1;
}

.selected-setting {
  background-color: #cce5ff;
}

.settings-right-side {
  /* border: solid 1px; */
  padding: 10px;
  padding-top: 0;
  max-height: 90vh;
  min-width: 90%;
  max-width: 90%;
  border-radius: 10px;
  box-shadow: 0px 0px 16px #dbe4df;
  overflow: auto;
  position: sticky;
  top: 10px;
}

.profile-header {
  display: flex;
  justify-content: space-around;
  gap: 10px;
}

.profile-header div h2 {
  text-align: center;
  padding: 0;
  margin: 0;
}

.resume-butn {
  padding: 3px 5px;
  background-color: #3b3c6e;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.ph-right {
  /* border: solid 1px; */
  width: 70%;
  border-radius: 10px;
  padding: 10px 5px;
}

.pinfo-form {
  width: 80%;
  min-width: 500px;
  box-shadow: 0px 3px 8px #dbe4df;
  padding: 10px;
}

.resume-div {
  display: flex;
  flex-direction: row-reverse;
}

.profile-title {
  text-align: center;
  position: sticky;
  top: 0;
  background-color: white;
  height: 42px;
}

.endoresed-icons {
  display: flex;
  gap: 10px;
  justify-content: space-evenly;
}

.personal-data-list {
  width: 100%;
}

.data-item {
  width: 100%;
  /* display: flex;
  gap: 10px;
  flex-wrap: wrap;
  text-align: center; */
}

.data-pair {
  width: 70%;
  height: 50px;
  margin: 0 auto;
  display: flex;
  /* width: 30%; */
}

.data-pair strong {
  display: inline-block;
  min-width: 150px;
  max-width: 150px;
}

.box-shadow {
  box-shadow: 0px 0px 8px #dbe4df;
  overflow: scroll;
}

.collapsing-div {
  padding: 10px;
  width: 80%;
  min-width: 400px;
  transition: 0.3s ease;
  position: relative;
}

.collapsing-div::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.mem-table {
  box-shadow: 0px 3px 8px #dbe4df;
  padding: 5px;
  overflow: auto;
}

th {
  text-align: center !important;
  min-width: 200px;
  max-width: 200px;
  height: 50px;
}

td {
  text-align: center !important;
  min-width: 200px;
  max-width: 200px;
  height: 50px;
}

.renew-mem {
  display: flex;
  flex-direction: row-reverse;
}

@media screen and (max-width: 1025px) {
  .profile-header {
    display: flex;
    flex-direction: column;
  }

  .ph-left {
    margin: 0 auto;
  }

  .ph-right {
    width: 90%;
  }

  .name-initial {
    max-width: 100%;
    margin-left: -70px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 90%;
  }

  .pinfo-form {
    margin: 0 auto;
  }

  .settings-right-side {
    min-width: 85%;
    max-width: 85%;
  }
}
