.main-container {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 50%;
  min-width: 600px;
  position: fixed;
  z-index: 99999;
  top: 3.1rem;
  right: 0;
  border: solid 1px #dbe4df;
  background-color: white;
  box-shadow: 0px 3px 8px #dbe4df;
  border-top-left-radius: 10px;
  overflow: auto;
  scrollbar-width: none;
  transition: 0.5s;
}

.close {
  position: sticky;
  top: 0;
  background-color: white;
  padding-left: 7px;
  cursor: pointer;
}

.notify-title {
  position: sticky;
  top: 20px;
}

.notify-card {
  width: 70%;
  min-width: 450px;
  margin: 0 auto;
  /* margin: 10px; */
  min-height: 40px;
  border-radius: 5px;
  padding: 10px 20px;
  box-shadow: 0px 0px 8px #dbe4df;
}

.notify-card:hover {
  box-shadow: 0px 0px 8px #cce5ff;
  background-color: #cce5ff;
}
