.dashboard-parent-div {
  padding: 0 10px;
  /* border: solid 1px; */
}

.dashboard-parent-div h3 {
  position: -webkit-sticky;
  position: sticky;
  width: -moz-fit-content;
  width: fit-content;
  left: 10px;
}

.dashboard-chart-data {
  display: flex;
  gap: 10px;
}

.highcharts-credits {
  display: none;
}