.credentials-div {
  margin: 0 auto;
  width: fit-content;
  overflow: auto;
}

.credentials-title {
  position: sticky;
  top: 0;
  left: 0;
  background: white;
  z-index: 9;
  height: 42px;
}

.cred-parent-div {
  padding: 0 10px;
}

.mem-table::-webkit-scrollbar,
.cred-parent-div::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
