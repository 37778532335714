.layout-container {
  display: flex;
  flex-direction: row;
  height: 970vh;
  background-color: #fff;
  overflow: hidden;
}

.left-side {
  background-color: #3c3b6e;
  height: 100vh;
  position: relative;
}

.right-side {
  background-color: #fff;
  height: 100vh;
  flex-grow: 1;
  overflow: hidden;
}

.header-container {
  background-color: #b22234;
  height: 50px;
  display: flex;
  align-items: center;
}

.content {
  padding: 10px;
  padding-top: 0;
  height: 94vh;
  overflow-y: auto;
  /* height: 40px; */
}

.content::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
