.navbar-logo {
  height: 60px;
}

.nav-menu {
  display: flex;
  align-items: center;
}

.nav-link-group,
.nav-auth {
  display: flex;
}

.nav-link-group a,
.nav-auth a {
  color: white;
  text-decoration: none;
  margin-right: 10px;
  padding: 10px;
}

.nav-auth a {
  border: 1px solid white;
  padding: 5px 15px;
  border-radius: 5px;
}

.nav-link-group a:hover,
.nav-auth a:hover {
  background-color: #ddd;
  color: black;
}

.container {
  margin-top: 25px;
}

.container_form {
  width: 900px;
  border: 1px solid grey;
  border-radius: 5px;
  padding: 0.25em 1em;
}

.subscription_align {
  text-align: left;
  display: flex;
  flex-wrap: wrap;
}

.sidebar {
  flex: 0 0 200px;
  background-color: #eee;
  padding: 20px;
}

.sidebar ul {
  list-style-type: none;
  padding-left: 0;
}

.sidebar li {
  padding: 10px;
  cursor: pointer;
  border: 1px solid #ccc;
}

.sidebar li.active {
  background-color: #ccc;
}

.form-content {
  padding: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-group {
  margin-bottom: 10px;
}

label {
  width: 185px;
}

.form-div {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 0.5em 2em;
}

.billing-div {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.billing-div div {
  min-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.form-group {
  width: 380px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.check-box-group {
  width: 380px;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
}

.form-group2 {
  width: 380px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.form-check {
  margin-left: 1em;
}

.form-check-input {
  position: relative;
  top: 5px;
  width: 15px;
  height: 15px;
}

.form-group input[type="text"],
.form-group input[type="email"],
.form-group input[type="password"],
.form-group input[type="date"] {
  width: 180px;
  height: 33px;
  padding: 6px;
  margin-top: 5px;
  border-radius: 5px;
  border: 1px solid grey;
}

.register-input-container {
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  padding: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  /* max-width: 300px; */
}

.register-input-container.active {
  outline: 2px solid #c2dbfe;
}

.register-input-container input {
  border: none;
  outline: none;
  flex: 1;
  padding-left: 10px;
  height: 30px;
}

.register-input-container img {
  height: 25px;
  margin-right: 5px;
}

select {
  width: 180px;
  height: 40px;
  padding: 6px;
  margin-top: 5px;
  border-radius: 5px;
  border: 1px solid grey;
}

h2 {
  text-align: center;
  margin-bottom: 1em;
}

.button-container {
  margin-top: 20px;
}

.button-container button {
  padding: 10px 15px;
  margin-right: 10px;
}

.form-check-label {
  width: 85%;
  margin-left: 0.5em;
  font-size: 12px;
  position: relative;
  top: 4px;
}

.flex0 {
  display: flex;
}

.flex0 div input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.flex0 div input:focus {
  outline: 2px solid #c2dbfe;
}

.expiry {
  border-bottom-left-radius: var(--bs-border-radius);
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  padding: 5px;
}

.cvv {
  border-bottom-right-radius: var(--bs-border-radius);
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  padding: 5px;
  margin-left: 1px;
}

.number_align {
  text-align: right;
  position: relative;
  top: 12px;
}

.sub_container {
  display: flex;
  column-gap: 2em;
  justify-content: space-between;
}

.btn_pay_now {
  width: 95%;
  margin: 15px 0px 10px;
}

hr {
  height: 280px;
}

/* stepper */
.stepper {
  display: flex;
  justify-content: space-around;
  position: relative;
}

.step {
  position: relative;
  height: 20px;
  width: 20px;
  padding: 20px;
  cursor: pointer;
  border-radius: 50%;
  background-color: #f0f0f0;
  transition: background-color 0.3s ease;
  z-index: 1;
  color: black;
  text-align: center;
  margin-bottom: 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.step.active {
  background-color: #90caf9;
}

.step::after {
  content: "";
  position: absolute;
  left: 39px;
  top: 50%;
  width: 620px;
  height: 4px;
  background-color: #4caf50;
  z-index: 0;
  cursor: auto;
}

.step.active ~ .step::before,
.step.active ~ .step::after {
  background-color: #cccccc;
}

.step:first-child::before,
.step:last-child::after {
  width: 0;
}

.step.active::after {
  background-color: lightgrey;
}

.step.active::before {
  background-color: #ff7f50;
}

/* ------- */

.btn_align {
  text-align: center;
  margin: 1em;
}

button {
  background-color: black;
  width: 150px;
  color: #ffffff;
  font-size: 15px;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
  background-color: #00000092;
  color: #ffffff;
}

.courseDesc {
  position: fixed;
  width: 100%;
  border: solid 1px;
  top: 60px;
  height: 100vh;
  background: white;
}

.course-planner-parent {
  display: flex;
  gap: 10px;
  width: fit-content;
  margin: 0 auto;
}

.course-planner-parent div {
  min-width: 250px;
  border: solid 1px gray;
  border-radius: 5px;
  text-align: center;
  padding: 10px;
  min-height: 200px;
}

.course-planner-parent div:hover {
  box-shadow: 0px 3px 8px #909793;
}

.course-planner-parent div ol {
  margin: 0;
  text-align: left;
  list-style: decimal;
  gap: 10px;
}

.course-plan-cancel {
  cursor: pointer;
  font-weight: bold;
  padding: 10px;
  width: fit-content;
}

/* Responsive styles */
@media (max-width: 768px) {
  .container {
    flex-direction: column;
  }

  .sidebar {
    order: 2;
    width: 100%;
  }

  .form-content {
    order: 1;
  }
}
