.toggle-switch {
  margin-top: 10px;
  display: flex;
  cursor: pointer;
  width: 180px;
  height: 40px;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 40px;
  height: 43px;
  background: white;
  z-index: 9;
}

.appointment-parent-div {
  max-height: 100%;
  overflow: auto;
}

.appointment-parent-div::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.appointments-title {
  position: sticky;
  top: 0;
  background: white;
  z-index: 9;
  height: 42px;
}

.option {
  padding: 5px 10px;
  border: 1px solid #d0d4e4;
  flex: 1;
  text-align: center;
  color: black;
  font-size: 0.8em;
  transition: background-color 0.3s ease;
}

.option:first-child {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.option:last-child {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.option.active {
  background-color: #cce5ff;
}

.tabular-div {
  /* border: solid 1px; */
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  overflow-y: auto;
}

.tabular-content {
  width: 40%;
  min-width: 400px;
  margin-top: 20px;
  /* border: solid 1px; */
  padding: 10px;
  border-radius: 5px;
  transition: transform 0.3s ease;
  box-shadow: 0px 3px 8px #909793;
}

.flexx-div10 {
  display: flex;
  gap: 10px;
  align-items: center;
}

.flexx-div2 {
  display: flex;
  gap: 2px;
  align-items: center;
}

.tabular-info-div {
  display: flex;
}

.tabular-info-left {
  min-width: 55%;
  max-width: 55%;
  border-right: solid 1px;
  padding-left: 17px;
}

.tabular-info-right {
  padding: 5px 10px;
}

.tabular-info-options {
  display: flex;
  flex-direction: row-reverse;
  font-size: small;
  gap: 5px;
}

.tabular-info-options div {
  border: solid 1px white;
  cursor: pointer;
  padding: 2px;
}

.tabular-info-options div:hover {
  border: solid 1px black;
  border-radius: 5px;
}
