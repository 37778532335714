.pw-div {
  position: fixed;
  min-width: 96vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  top: 50px;
  padding: 10% 0%;
  opacity: 0.9;
  z-index: 99;
}

.pw-form-div {
  width: fit-content;
  margin: 0 auto;
  border: solid 1px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 5px;
  background-color: white;
}

.pw-buttons {
  display: flex;
  justify-content: space-around;
}

.pw-form-div div input {
  width: 250px;
  border: none;
  border-bottom: solid 1px gray;
  outline: none;
}
