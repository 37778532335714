.sidebar-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: ease 0.3s;
}

.sidebar-container.minimized {
  width: 80px;
}

.sidebar-container.maximum {
  width: 250px;
}

.logo-container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100px;
}

.circle-wrapper {
  width: 50px;
  height: 50px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.8em;
  margin: 10px 0;
  color: #b22234;
}

.user-name span {
  color: #fff;
  font-size: 1.2em;
  font-family: "Roboto", sans-serif;
}

.menu-item-list {
  height: calc(100% - 100px);
  align-self: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  justify-content: space-between;
}

.menu-item {
  display: flex;
  align-items: center;
  height: 50px;
  color: #fff;
  font-size: 1.2em;
  font-family: "Roboto", sans-serif;
  cursor: pointer;
  transition: ease 0.3s;
  padding: 0 0 0 25px;
  border-top: 1px solid #3c3b6e;
  border-bottom: 1px solid #3c3b6e;
}

.menu-item:hover::before {
  content: "";
  height: 50px;
  width: 8px;
  background-color: #fff;
  position: absolute;
  left: 0;
}

.menu-item:hover,
.active {
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
}

.menu-item-name {
  margin-left: 10px;
  color: white;
  list-style: none;
}

.flex-div {
  display: flex;
  align-items: center;
  gap: 10px;
}

/* li {
  list-style: none;
} */

.link-no-style {
  text-decoration: none;
  color: inherit;
  align-items: center;
}
