.navbar-container {
  height: 100vh;
  overflow: auto;
}

.navbar-container::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.navbar {
  height: auto;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
  transition: height 0.3s;
  overflow: auto;
}

.navbar::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.navbar-shrink {
  height: 50px;
}

.navbar-logo {
  height: 80px;
  transition: height 0.3s;
}

.logo-shrink {
  height: 25px;
  padding: 7.5px 0;
}

.navbar-logo {
  height: 80px;
}

.nav-menu {
  display: flex;
  align-items: center;
}

.nav-link-group,
.nav-auth {
  display: flex;
}

.nav-link-group a,
.nav-auth a {
  color: white;
  text-decoration: none;
  margin-right: 10px;
  padding: 10px;
}

.nav-auth a {
  border: 1px solid white;
  padding: 5px 15px;
  border-radius: 5px;
}

.nav-link-group a:hover,
.nav-auth a:hover {
  background-color: #ddd;
  color: black;
}

.main-section {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background: url("../../assets/images/landing-page.png") no-repeat center
    center scroll;
  background-size: cover;
}

.main-content {
  position: absolute;
  top: 70.5%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  z-index: 1;
  width: 80%;
  padding: 20px;
  background: rgba(0, 0, 0, 0.5);
}

.section {
  display: flex;
  flex-wrap: wrap;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  padding: 50px;
  width: 100%;
}

.section1 {
  display: flex;
  flex-wrap: wrap;
  /* min-height: 100vh; */
  align-items: center;
  justify-content: center;
  padding-top: 50px;
  width: 100%;
}

.section:nth-child(even) {
  background-color: #f8f8f8;
}

.contents {
  flex: 1;
  max-width: 50%;
  padding: 20px;
  text-align: justify;
}

.contents > p {
  font-size: 1.2rem;
}

.image {
  flex: 1;
  max-width: 50%;
  /* background-image: url('../../../assets/pta-traning.png'); */
  background-size: cover;
  background-position: center;
  overflow: hidden;
}

.section-heading {
  font-size: 2.5em;
  margin-bottom: 0.75em;
  text-align: center;
}

.contact_info_align {
  display: flex;
  column-gap: 0.5em;
  font-size: 20px;
  margin-bottom: 0.5em;
}

a {
  cursor: pointer;
}

.wrapper {
  /* min-width: 80%; */
  max-width: 1200px;
  overflow: hidden;
  margin: 0 auto;
}
/* 
@media (min-width: 1920px) {
  .wrapper {
    max-width: 1500px;
  }

  .section {
    min-height: auto;
  }
} */

@media (min-width: 1440px) {
  .nav-link-group {
    display: none;
  }

  .wrapper {
    width: 1224px;
  }
  .section {
    min-height: auto;
  }
}

@media (min-width: 1280px) {
  .wrapper {
    width: 1192px;
  }

  .nav-link-group {
    display: none;
  }

  .section {
    min-height: auto;
  }
}

@media (min-width: 1024px) {
  .wrapper {
    width: 1192px;
  }
  .section {
    min-height: auto;
  }
}

@media (max-width: 768px) {
  .content,
  .image {
    max-width: 100%;
  }

  .content {
    order: 2;
  }

  .image {
    order: 1;
    min-height: 50vh;
  }
}
