.header-container {
  display: flex;
  justify-content: space-between;
  padding-right: 5px;
  max-width: 100%;
}

.minimize-icon-class svg {
  cursor: pointer;
}

.icon-reverse {
  transform: rotate(180deg);
}

.circle-wrappers {
  width: 30px;
  height: 30px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  margin: 10px 0;
  color: #b22234;
}

.header-options {
  display: flex;
  align-items: center;
  gap: 10px;
}
