.container-cus {
  height: 100vh;
  width: 100%;
  background: #001428;
  padding-top: 50px;
}

.box {
  margin: 0px auto;
  width: 40vw;
  min-width: 320px;
  height: fit-content;
  padding: 32px 40px;
  background: rgb(255, 255, 255);
  border-radius: 20px;
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 10px;
  box-sizing: border-box;
  color: rgb(94, 108, 132);
}

.nav-container {
  width: 80%;
  margin: 0 auto;
  margin-top: -20px;
}

.loginform {
  border: solid 1px;
  border-radius: 20px;
  /* height: 95%; */
}

.login-but {
  width: 40%;
  background-color: #ff554d;
  color: white;
  border-color: #ff554d;
}

.reset {
  width: fit-content;
  color: #ff554d;
  cursor: pointer;
}

.rest:hover {
  font-weight: bold;
}

.logo-container {
  display: flex;
  justify-content: space-around;
}

.input-container {
  margin: 0 auto;
  height: 50%;
  width: 80%;
}

.logo {
  /* margin-left: 40%; */
  max-width: 1800px;
  max-height: 90px;
}

.individual-format {
  /* display: flex; */
  /* margin: 0 auto; */
}

.flexx-around {
  display: flex;
  justify-content: space-around;
}

@media screen and (max-width: 575px) {
  /*.box{
        margin-left: 20vw;
        position: relative;
        top: 10vh;
        width: 60vw;
        height: 60%;
        padding: 0px;
        background: rgb(255, 255, 255);
        border-radius: 3px;
        box-shadow: rgb(0 0 0 / 10%) 0px 0px 10px;
        box-sizing: border-box;
        color: rgb(94, 108, 132);
    }

    .input{
        height: 50px;
    }

    .label{
        font-size: medium;
    }*/

  .logo {
    width: 100px;
    height: 50px;
  }

  .nav-container {
    font-size: small;
  }
}

@media screen and (min-width: 576px) {
  /*.box{
        margin-left: 15vw;
        width: 60vw;
        height: 65vh;
        padding: 0px;
        background: rgb(255, 255, 255);
        border-radius: 3px;
        box-shadow: rgb(0 0 0 / 10%) 0px 0px 10px;
        box-sizing: border-box;
        color: rgb(94, 108, 132);
    }*/

  .logo {
    width: 100px;
    height: 50px;
  }

  .nav-container {
    font-size: small;
  }
}

@media screen and (min-width: 768px) {
  /*.box{
        margin: 20px auto;
        position: relative;
        width: 50vw;
        height: 80vh;
        padding: 0px;
        background: rgb(255, 255, 255);
        border-radius: 3px;
        box-shadow: rgb(0 0 0 / 10%) 0px 0px 10px;
        box-sizing: border-box;
        color: rgb(94, 108, 132);
        overflow: hidden;
    }

    .logo{
        margin-left: 22vw;
        width: 100px;
        height: 50px;
    }*/

  .nav-container {
    font-size: small;
  }
}

@media screen and (min-width: 992px) {
  .box {
    width: 45vw;
    height: fit-content;
    position: relative;
    top: 10%;
    padding: 32px 40px;
    background: rgb(255, 255, 255);
    border-radius: 15px;
    box-shadow: rgb(0 0 0 / 10%) 0px 0px 10px;
    box-sizing: border-box;
    color: rgb(94, 108, 132);
  }

  .container-cus {
    /* height: 90%;
        width: 100%;
        background-color: #fff; */
    padding-top: 10px;
  }

  /*.loginform{
        border: solid 1px;
        border-radius: 15px;
        height: 90%;
    }
    
    .login-but{
        width: 40%;
    }*/
  .nav-container {
    font-size: medium;
  }

  .logo {
    width: 130px;
    height: 65px;
  }
}
