.courses-parent-div {
  padding: 0 10px;
  /* border: solid 1px; */
}

.courses-parent-div h3 {
  position: sticky;
  width: fit-content;
  left: 10px;
}

.course-table-div {
  width: fit-content;
  /* margin: 0 auto; */
  border-radius: 5px;
  /* border: solid 1px; */
  box-shadow: 0px 3px 8px #909793;
  padding: 5px 10px;
}

.course-th {
  text-align: center !important;
  min-width: 200px;
  max-width: 200px;
  height: 50px;
}

.course-th-50 {
  text-align: center !important;
  min-width: 50px !important;
  max-width: 50px !important;
  height: 50px;
}

.course-th-20 {
  text-align: center !important;
  min-width: 20px !important;
  max-width: 20px !important;
  height: 50px;
}

.course-td {
  text-align: center !important;
  min-width: 200px;
  max-width: 200px;
  height: 50px;
}

.course-td div {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.course-new {
  display: flex;
  flex-direction: column;
  flex-direction: row-reverse;
}

.course-form-parent-div {
  width: 100%;
}

.course-form-div {
  padding: 20px 40px;
  display: flex;
  width: fit-content;
  flex-direction: column;
  gap: 15px;
  border-radius: 5px;
  /* margin: 0 auto; */
  box-shadow: 0px 3px 8px #909793;
}

.course-form-div div input,
.course-form-div div select {
  width: 250px;
  border: none;
  border-bottom: solid 1px gray;
  border-radius: 0;
}

.course-buttons {
  display: flex;
  justify-content: space-around;
}

.course-history-div {
  box-shadow: 0px 3px 8px #909793;
  padding: 10px 20px;
  width: fit-content;
  /* margin: 0 auto; */
  display: flex;
  border-radius: 5px;
  gap: 10px;
}

.course-history-div div {
  width: 200px;
  text-align: center;
}

.course-history-div label {
  font-weight: bold;
}

.history-header {
  position: relative;
}

.history-close {
  position: absolute;
  right: 0;
  font-weight: bold;
  cursor: pointer;
}
